import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { DatepickerApi } from "../../s25-datepicker/date.picker.api";
import { PreferenceService } from "../../../services/preference.service";
import { jSith } from "../../../util/jquery-replacement";
import { PopoverComponent } from "../../s25-popover/popover.component";
import { DayChooserPref } from "../../s25-day-chooser/s25.day.chooser.component";

@TypeManagerDecorator("s25-ng-opt-date-options")
@Component({
    selector: "s25-ng-opt-date-options",
    template: `
        @if (!!hasDatepicker) {
            <s25-ng-inline-datepicker
                [firstDate]="date"
                [weeks]="hasWeeksChooser ? weeks : 0"
                [title]="dateOptionsLabel[datesOptionValue]"
                (firstDateChange)="onDatepickerChange($event)"
            />
        }
        @if (hasDaysSelector) {
            <div class="ngOptElementPad c-weeksChooser--flex c-optionsBar__weeks-select">
                <label for="daysChooser" class="ngInline">Days:</label>
                <select
                    class="ngInlineBlock ngListPageDropdown cn-form__control"
                    name="daysChooser"
                    aria-label="Number of days"
                    [(ngModel)]="chosenDays"
                    (ngModelChange)="onDatepickerChange()"
                >
                    @for (d of days; track d) {
                        <option [ngValue]="d">{{ d }}</option>
                    }
                </select>
            </div>
        }
        @if (!!hasWeeksChooser && weeks) {
            <div class="ngOptElementPad c-weeksChooser--flex">
                <label class="ngInline">Weeks:</label>
                <div class="ngInlineBlock ngOptBumpUp">
                    <s25-num-weeks-chooser [weeks]="weeks" (onChange)="numWeeksChange($event)"></s25-num-weeks-chooser>
                </div>
            </div>
        }
        @if (!!hasDaysChooser) {
            <div class="ngInlineBlock ngOptElementPad c-margin-top--half--medium">
                <s25-ng-day-chooser [skipDaysPref]="skipDaysPref" (onChange)="daysAction($event)"></s25-ng-day-chooser>
            </div>
        }
    `,
    styles: `
        :host {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: flex-end;
            max-width: 100%;
        }

        .c-weeksChooser--flex {
            display: flex;
            align-items: baseline;
        }

        .c-weeksChooser--flex label {
            margin-right: 0.3125rem;
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25OptDateOptionsComponent implements OnChanges {
    @Input() weekStart: number;
    @Input() weeks: number = 1;
    @Output() weeksChange = new EventEmitter<number>();
    @Input() hasDatepicker: boolean;
    @Input() hasVariableDatepicker: boolean;
    @Input() hasWeekDatepicker: boolean;
    @Input() hasDaysSelector: boolean;
    @Input() hasWeeksChooser: boolean;
    @Input() hasDaysChooser: boolean;
    @Input() isCalendar: boolean;
    @Input() date: Date;
    @Input() endDate: Date;
    @Input() dateFormat: string;
    @Input() datesOptionValue: string;
    @Input() skipDaysPref: boolean;
    @Output() getData = new EventEmitter<boolean>();
    @Output() onChange = new EventEmitter<{ key: string; value: any }>();
    @Output() onDatePickerChange = new EventEmitter<Date>();
    @Output() removeButtonClasses = new EventEmitter<undefined>();
    @Output() daysChange = new EventEmitter<DayChooserPref>();
    @Output() endDateChange = new EventEmitter<Date>();
    chosenDays = 1;
    title: string;

    @ViewChild(PopoverComponent) datepickerPopover: PopoverComponent;

    private static MAX_DAYS = 90;
    days: number[] = new Array(S25OptDateOptionsComponent.MAX_DAYS).fill(null).map((_, i) => i + 1);

    readonly dateOptionsLabel = {
        futureOnly: "Future Only",
        allDates: "All Dates",
        recentHistory: "Recent and Future",
    };

    constructor(
        private elementRef: ElementRef,
        private changeDetector: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.weekStart) this.refresh();
        if (changes.weekStart || changes.endDate || changes.dateFormat || changes.datesOptionValue || changes.date)
            this.setDateTitleFromBean();
    }

    _onChangeSynchronized() {
        this.setDateTitleFromBean();
        this.getData.emit(false);
    }

    refresh() {
        if (this.hasDaysSelector) {
            this.endDate = S25Util.date.toEndOfDay(S25Util.date.addDays(this.date, this.chosenDays - 1));
        } else if (this.hasWeeksChooser && this.weekStart !== undefined) {
            this.date = S25Util.date.firstDayOfWeek(this.date, this.weekStart);
            this.onChange.emit({ key: "date", value: this.date });
            this.endDate = S25Util.date.addDays(this.date, 6 + (this.weeks - 1) * 7); // End of week + so many weeks
        }
        this.endDateChange.emit(this.endDate);
        this.onChange.emit({ key: "endDate", value: this.endDate });
        DatepickerApi.refresh(this.elementRef.nativeElement);
    }

    updateDateArrow(type: "back" | "forward") {
        this.elementRef.nativeElement.blur();
        const days = (type === "back" ? -1 : 1) * (this.hasWeeksChooser ? this.weeks * 7 : 1);
        this.onDatepickerChange(S25Util.date.addDays(this.date, days)); // Refresh datepicker
    }

    onDatepickerChange(date?: Date) {
        if (this.datepickerPopover) this.datepickerPopover.forceClose(); // Close datepicker when date is selected
        if (date) this.date = date;
        if (this.hasWeeksChooser) this.date = S25Util.date.firstDayOfWeek(this.date, this.weekStart);
        this.enableDays();
        this.removeButtonClasses.emit(); // Remove button classes for custom date range
        this.onDatePickerChange.emit(this.date);
        this.refresh(); // Update other datepicker, too
        this._onChangeSynchronized(); // Let scope propagate
    }

    numWeeksChange(weeks: number) {
        this.weeks = weeks;
        this.weeksChange.emit(weeks);
        this.onDatepickerChange();
    }

    daysAction(daysOfWeek: DayChooserPref) {
        this.onChange.emit({ key: "daysOfWeek", value: daysOfWeek });
        this.daysChange.emit(daysOfWeek);
        this.onDatepickerChange();
    }

    enableDays() {
        const elems = jSith.find(this.elementRef.nativeElement, ".c-optionsBar__weeks-select");
        elems?.forEach((elem: Element) => elem.classList.add("c-optionsBar__weeks-select--show"));
    }

    setDateTitle = (date: any, endDate: any, dateFormat: string, datesOptionValue: string) => {
        if (datesOptionValue === "futureOnly") this.title = "Future Only";
        else if (datesOptionValue === "allDates") this.title = "All Dates";
        else if (datesOptionValue === "recentHistory") this.title = "Recent and Future";
        else this.title = S25Util.date.fromToString(date, endDate, dateFormat);
        this.changeDetector.detectChanges();
    };

    setDateTitleFromBean = () => {
        this.setDateTitle(this.date, this.endDate, this.dateFormat, this.datesOptionValue);
    };
}
